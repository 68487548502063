import { graphql, Link } from 'gatsby'
import React, { useState } from 'react'
import Layout from '../../components/Layout'
import PageTitle from '../../components/PageTitle'
import * as styles from "../../sass/products.module.scss"

export default function Products({ data }) {
  const products = data.allMarkdownRemark.nodes
  const categories = data.allMarkdownRemark.distinct
  const totalCount = data.allMarkdownRemark.totalCount
  const [currentCategory, setCurrentCategory] = useState('Все');

  // function handleChangeCategory(event)
  // {
  //   GetProductsByCategory(event.target.value)
  // }

  function GetProductsByCategory() {
    // const data = graphql`
    // query ProductsByCategory($category: String) {
    //     allMarkdownRemark(
    //       filter: {frontmatter: {category: {eq: $category}}}
    //     ) {
    //         nodes {
    //           frontmatter {
    //             slug
    //             title
    //             text
    //           }
    //           id
    //         }
    //         totalCount
    //       }
    //   }
    // `
    // console.log({data})
    console.log(currentCategory)
  }

  return (
    <Layout>
      <PageTitle text="Продукция" />

      <section className={styles.category}>
        <div className={styles.categoryTotal}>Всего позиций: { totalCount }</div>
        <div>
          <select 
          name="" id="" 
          className={styles.categorySelect}
          onChange={ e => {
            setCurrentCategory(e.target.value);
          }}
          onBlur={ e => {
            setCurrentCategory(e.target.value);
          }}
          >
            <option value="Все">Все</option>
            {categories.map(category => (
              
              <option key={ category } value={ category }>{ category }</option>
            ))

            }
          </select>
        </div>
      </section>

      <section>
        <div>
          {products.map(product => (
            <Link to={"/products/" + product.frontmatter.slug} key={product.id} className={styles.productLink} >
                <div className={styles.productCard}>
                  <div className={styles.productImg}>
                    <img src={'/img/products/'+ product.frontmatter.slug +'/preview.jpg'} alt="preview" />
                  </div>
                  <div className={styles.productCardContent}>
                    <h3 className={styles.productCardTitle}>{product.frontmatter.title}</h3>
                    <p className={styles.productCardText}>{product.frontmatter.text}</p>
                  </div>
                </div>
            </Link>
          ))}
        </div>
      </section>
    </Layout>
  )
}


// export page query
export const query = graphql`
query ProductsPage {
    allMarkdownRemark {
        distinct(field: frontmatter___category)
        nodes {
          frontmatter {
            slug
            category
            title
            text
          }
          id
        }
        totalCount
      }
  }
`